import './AppointmentScheduleList.less'

import classNames from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { RecordStatus, Schedule, ScheduleType, ScheduleVisit, updateSchedule } from '../../../../../../requests'
import { routes } from '../../../../../../routes'
import { execCopyCommand, localeFromPath } from '../../../../../../utils'
import { DatacDropdownMenu, DatacDropdownMenuOptions, DatacMessage, DatacRecordStatusTag } from '../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../RecruitmentStudyDetailsStore'
import { ScheduleListSlots } from './ScheduleListSlots'

interface Props {
  schedules: Schedule[]
  fetchSchedules: () => void
}

enum ScheduleActions {
  CopyLink = 'copy_link',
  Publish = 'publish',
  Unpublish = 'unpublish'
}

export const AppointmentScheduleList: React.FC<Props> = ({ schedules, fetchSchedules }) => {
  const intlSchedules = useScopedIntl('recruitment.study.schedules')
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>()
  const [selectedVisit, setSelectedVisit] = useState<ScheduleVisit>()
  const intl = useScopedIntl('')
  const { study } = useRecruitmentStudyDetailsStore()

  useEffect(() => {
    if (!schedules || schedules.some(s => s.id === selectedSchedule?.id)) return
    setSelectedSchedule(schedules[0])
    setSelectedVisit(schedules[0]?.visits[0])
  }, [schedules])

  const onChooseSchedule = (scheduleId: number) => {
    setSelectedSchedule(schedules.find(schedule => schedule.id === scheduleId))
    setSelectedVisit(schedules.find(schedule => schedule.id === scheduleId)?.visits[0])
  }

  const menuOptions = (isPublished: boolean): DatacDropdownMenuOptions<ScheduleActions> =>
    isPublished
      ? [
          {
            type: ScheduleActions.CopyLink,
            label: 'recruitment.study.schedules.copy_link',
            icon: 'copy'
          },
          {
            type: ScheduleActions.Unpublish,
            label: 'recruitment.study.schedules.unpublish',
            description: 'recruitment.study.schedules.dont_allow_book_online',
            icon: 'xCircle'
          }
        ]
      : [
          {
            type: ScheduleActions.Publish,
            label: 'recruitment.study.schedules.publish',
            description: 'recruitment.study.schedules.allow_book_online',
            icon: 'checkCircle'
          }
        ]

  const copyScheduleLinkToClipboard = (scheduleId: number) => {
    try {
      execCopyCommand(
        `${window.location.host}/${localeFromPath()}${routes.subjectBookAppointment(study.id, scheduleId)}`
      )
      DatacMessage.success(intl('common.copy.success_title'), intl('common.copy.success_description'))
    } catch {
      DatacMessage.warning(intl('common.copy.failure_title'), intl('common.copy.failure_description'))
    }
  }

  const onScheduleVisibilityChange = (scheduleId: number, isPublished: boolean) => {
    const schedule = schedules.find(s => s.id === scheduleId)
    if (!schedule) return

    updateSchedule(
      { ...schedule, isPublished, studyId: study.id },
      {
        onSuccess: () => {
          fetchSchedules()
          const prefix = isPublished ? 'published' : 'unpublished'
          DatacMessage.success(intlSchedules(`${prefix}.success`), intlSchedules(`${prefix}.description`))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const handleMenuClick = (item: string, scheduleId: number) => {
    switch (item) {
      case ScheduleActions.CopyLink:
        copyScheduleLinkToClipboard(scheduleId)
        break
      case ScheduleActions.Publish:
        onScheduleVisibilityChange(scheduleId, true)
        break
      case ScheduleActions.Unpublish:
        onScheduleVisibilityChange(scheduleId, false)
        break
      default:
        break
    }
  }

  return (
    <div className="appointment-schedule-list">
      <div className="appointment-schedule-list__menu">
        {schedules.map((schedule, index) => {
          const bookedCount = schedule.visits.reduce((acc, visit) => acc + (visit.bookingsCount || 0), 0)
          const allCount = schedule.participantsCount * schedule.visitsCount

          return (
            <Fragment key={index}>
              <div
                className={classNames('appointment-schedule-list__menu__schedule', {
                  selected: selectedSchedule?.id === schedule.id
                })}
                onClick={() => onChooseSchedule(schedule.id)}
              >
                <div className="appointment-schedule-list__menu__number">{index + 1}</div>
                <div className="appointment-schedule-list__menu__title">
                  {schedule.title}
                  <DatacRecordStatusTag
                    status={schedule.isPublished ? RecordStatus.Completed : RecordStatus.NotStarted}
                    label={intlSchedules(schedule.isPublished ? 'published' : 'unpublished')}
                  />
                </div>
                <div className="appointment-schedule-list__menu__booked">
                  {bookedCount} / {allCount}
                </div>
                <DatacDropdownMenu
                  options={menuOptions(schedule.isPublished)}
                  onClick={item => handleMenuClick(item, schedule.id)}
                />
              </div>
              {selectedSchedule?.id === schedule.id &&
                schedule.visits.map(visit => {
                  return (
                    <div
                      key={visit.id}
                      className={classNames('appointment-schedule-list__menu__visit', {
                        selected: selectedVisit.id === visit.id
                      })}
                      onClick={() => setSelectedVisit(visit)}
                    >
                      <div className="appointment-schedule-list__menu__title">{visit.title}</div>
                      <div className="appointment-schedule-list__menu__booked">
                        {visit.bookingsCount || 0} / {schedule.participantsCount}
                      </div>
                      <div className="appointment-schedule-list__menu__space" />
                    </div>
                  )
                })}
            </Fragment>
          )
        })}
      </div>

      <div className="appointment-schedule-list__right">
        <ScheduleListSlots
          scheduleId={selectedSchedule?.id}
          visit={selectedVisit}
          selectionDisabled={
            selectedSchedule?.type === ScheduleType.Sequential && selectedVisit?.id !== selectedSchedule?.visits[0]?.id
          }
          fetchSchedules={fetchSchedules}
        />
      </div>
    </div>
  )
}
