import './VisitHeader.less'

import { Popover, Progress } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import { RecordStatus, Schedule } from '../../../../../../../requests'
import { DatacIcon, DatacRecordStatusTag, DatacTitle, DatacToggleExpand } from '../../../../../../common'
import { VisitSlotsCounts } from '../../AppointmentSchedule'
import { ChangeVisitWrapper } from '../AppointmentScheduleVisit'

interface VisitHeaderProps {
  schedule: Schedule
  visitNumber: number
  setNumberOfVisitToEdit: (number: number) => void
  onClose: () => void
  slotsCounts: VisitSlotsCounts[]
}

export const VisitHeader: React.FC<VisitHeaderProps> = ({
  visitNumber,
  onClose,
  schedule,
  setNumberOfVisitToEdit,
  slotsCounts
}) => {
  const intlVisit = useScopedIntl('recruitment.study.schedules.visit')
  const [currentSchedule, setCurrentSchedule] = useState<Schedule>()
  const [currentVisitSlotsCounts, setCurrentVisitSlotCounts] = useState<VisitSlotsCounts>()
  const [isListVisible, setIsListVisible] = useState(false)

  useEffect(() => {
    setCurrentSchedule(schedule)
    setCurrentVisitSlotCounts(slotsCounts[visitNumber - 1])
  }, [schedule, slotsCounts, visitNumber])

  const getSlotsStatus = () => {
    if (!currentVisitSlotsCounts) return RecordStatus.Created
    if (currentVisitSlotsCounts.created >= currentVisitSlotsCounts.needed) return RecordStatus.Completed
    return RecordStatus.InProgress
  }

  const getProgress = () => {
    if (!currentSchedule?.visitsCount) return 0

    const finishedVisits =
      slotsCounts.filter(
        ({ needed, created }, index) => created >= needed && currentSchedule.visits?.find(v => v.number === index + 1)
      )?.length || 0

    return (finishedVisits / currentSchedule.visitsCount) * 100
  }

  const popoverContent = (
    <div className="appointment-schedule-visit-header__popover">
      <div className="appointment-schedule-visit-header__popover__item__body">
        <div className="appointment-schedule-visit-header__popover__item__body__number done">
          <DatacIcon name="check" raw />
        </div>
        <div className="appointment-schedule-visit-header__popover__item__body__title">{intlVisit('set_up')}</div>
      </div>
      {new Array(currentSchedule?.visitsCount).fill(0).map((_, index) => {
        const scheduleVisit = currentSchedule?.visits?.find(v => v.number === index + 1)
        const currentSlotsCounts = slotsCounts[index] || { created: 0, needed: 0 }

        const getProgressClass = () => {
          if (!scheduleVisit) return ''
          if (currentSlotsCounts.created >= currentSlotsCounts.needed) return 'done'
          return 'in-progress'
        }

        return (
          <div key={index} className="appointment-schedule-visit-header__popover__item">
            <div className="appointment-schedule-visit-header__popover__item__spacer" />
            <ChangeVisitWrapper
              showConfirm={!!scheduleVisit && !!currentSchedule?.visits?.find(v => v.number === index + 1)}
              onClick={() => scheduleVisit && setNumberOfVisitToEdit(index + 1)}
            >
              <div
                className={`appointment-schedule-visit-header__popover__item__body ${scheduleVisit ? 'clickable' : ''}`}
              >
                <div className={`appointment-schedule-visit-header__popover__item__body__number ${getProgressClass()}`}>
                  {index + 1}
                </div>
                <div
                  className={`appointment-schedule-visit-header__popover__item__body__title ${
                    visitNumber === index + 1 ? 'active' : ''
                  }`}
                >
                  {intlVisit('create', { number: index + 1 })}
                </div>
                {scheduleVisit && (
                  <DatacIcon
                    name="edit"
                    type="blue"
                    className="appointment-schedule-visit-header__popover__item__body__edit"
                  />
                )}
              </div>
            </ChangeVisitWrapper>
          </div>
        )
      })}
    </div>
  )

  return (
    <div className="appointment-schedule-visit-header">
      <div className="appointment-schedule-visit-header__left">
        <Progress type="circle" percent={getProgress()} strokeWidth={12} showInfo={false} />
        <div className="appointment-schedule-visit-header__left__title">
          <div className="appointment-schedule-visit-header__left__title__step">
            {intlVisit('step', { number: visitNumber, total: currentSchedule?.visitsCount })}
          </div>
          <DatacTitle size="medium">{intlVisit('create', { number: visitNumber })}</DatacTitle>
        </div>
        <DatacRecordStatusTag
          status={getSlotsStatus()}
          label={intlVisit(currentVisitSlotsCounts?.created === 1 ? 'slot' : 'slots', {
            count: currentVisitSlotsCounts?.created || 0
          })}
          coloredBackground
        />
        <Popover
          open={isListVisible}
          onOpenChange={setIsListVisible}
          content={popoverContent}
          placement="bottomRight"
          trigger="click"
        >
          <DatacToggleExpand type="grey" isCollapsed={!isListVisible} onToggle={v => setIsListVisible(!v)} />
        </Popover>
      </div>
      <DatacIcon className="datac-modal__close-icon" name="x" type="grey" onClick={onClose} />
    </div>
  )
}
