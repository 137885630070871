import './GeneralAvailability.less'

import { Button, Form, Popover } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Dayjs } from 'dayjs'
import React, { useState } from 'react'

import { getTimeAsNumber } from '../..'
import { useScopedIntl } from '../../../../../../../../hooks'
import { ScheduleVisitAvailability } from '../../../../../../../../requests'
import { validateRequired } from '../../../../../../../../validation'
import { DatacFormItem, DatacTimezonesSelect } from '../../../../../../../common'
import { GeneralAvailabilityRow } from './GeneralAvailabilityRow'

interface AppointmentScheduleGeneralAvailabilityProps {
  formInstance: FormInstance
  duration: number
  setAvailability: (availability: ScheduleVisitAvailability[]) => void
  missingPeriod: number
  currentTimezone: string
  setCurrentTimezone: (timezone: string) => void
  previousVisitFirstAvailabilityDate: Dayjs
}

export const AppointmentScheduleGeneralAvailability: React.VFC<AppointmentScheduleGeneralAvailabilityProps> = ({
  formInstance,
  duration,
  setAvailability,
  missingPeriod,
  currentTimezone,
  setCurrentTimezone,
  previousVisitFirstAvailabilityDate
}) => {
  const intlAvailability = useScopedIntl('recruitment.study.schedules.visit.availability')
  const intl = useScopedIntl('')
  const [isTimezonePopoverOpen, setTimezonePopoverOpen] = useState(false)

  const getValue = (index: number, name: string) => formInstance.getFieldValue(['availability', index, name])

  const onTimezoneChange = (timezone: string) => {
    setCurrentTimezone(timezone)
    setTimezonePopoverOpen(false)
  }

  return (
    <div className="appointment-schedule-general-availability">
      <DatacFormItem
        label={intlAvailability('label')}
        description={intlAvailability('description')}
        name="availability"
        validate={validateRequired(intl('common.required'))}
        additionalHeaderContent={
          <div className="appointment-schedule-general-availability__timezone">
            <Popover
              content={
                <div className="appointment-schedule-general-availability__timezone__popover">
                  <DatacTimezonesSelect showLabel defaultTimezone={currentTimezone} onChange={onTimezoneChange} />
                </div>
              }
              placement="bottomRight"
              trigger="click"
              open={isTimezonePopoverOpen}
              onOpenChange={setTimezonePopoverOpen}
            >
              <Button type="default">{intl('common.timezone')}</Button>
            </Popover>
          </div>
        }
      >
        <Form.List name="availability">
          {(fields, { add }) => {
            const currentRows = formInstance.getFieldValue('availability') as ScheduleVisitAvailability[]

            const onAdd = (date: Dayjs, isSameDay: boolean, index: number) => {
              const newRow = { date, isSameDay }
              add(newRow, index)
              formInstance.validateFields(['availability'])
            }

            const onRemove = (index: number) => {
              const currentAvailability = [...currentRows]
              currentAvailability.splice(index, 1)

              const newAvailability = currentAvailability.map((row, i) => ({
                ...row,
                isSameDay: i === 0 ? false : currentAvailability[i - 1].date.isSame(row.date, 'day')
              }))

              formInstance.setFieldsValue({ availability: newAvailability })
              setAvailability(newAvailability)
            }

            const onChangeDate = (date: Dayjs, index: number) => {
              const currentAvailability = [
                ...currentRows.slice(0, index),
                { ...currentRows[index], date },
                ...currentRows.slice(index + 1)
              ]

              const newAvailability = currentAvailability.map((row, i) => ({
                ...row,
                date: currentAvailability[row.isSameDay ? i - 1 : i]?.date
              }))

              formInstance.setFieldsValue({ availability: newAvailability })
              setAvailability(newAvailability)
            }

            const onChangeStartTime = (time: string, index: number) => {
              const currentAvailability = [
                ...currentRows.slice(0, index),
                { ...currentRows[index], startTime: time },
                ...currentRows.slice(index + 1)
              ]

              formInstance.setFieldsValue({ availability: currentAvailability })
              setAvailability(currentAvailability)
            }

            const onChangeEndTime = (time: string, index: number) => {
              const currentAvailability = [
                ...currentRows.slice(0, index),
                { ...currentRows[index], endTime: time },
                ...currentRows.slice(index + 1)
              ]

              formInstance.setFieldsValue({ availability: currentAvailability })
              setAvailability(currentAvailability)
            }

            return (
              <div>
                {fields.map(field => {
                  const index = field.name

                  const isSameDay = getValue(index, 'isSameDay')
                  const currentDate = getValue(index, 'date')

                  const dates = currentRows.filter(row => !row.isSameDay).map(row => row.date)
                  const startTime = getValue(field.name, 'startTime')
                  const endTime = getValue(field.name, 'endTime')
                  const dateIndex = dates.indexOf(currentDate)

                  const minTime = isSameDay ? getTimeAsNumber(currentRows[index - 1]?.endTime) + 1 : 0
                  const maxTime = currentRows[index + 1]?.isSameDay
                    ? getTimeAsNumber(currentRows[index + 1]?.startTime) - 1
                    : 24 * 60

                  const haveTimeForOneSlot =
                    !!endTime && getTimeAsNumber('23:59') - getTimeAsNumber(endTime) >= (duration || 15)

                  const shouldShowAdd =
                    currentDate &&
                    startTime &&
                    haveTimeForOneSlot &&
                    (!currentRows[index + 1] || !currentRows[index + 1]?.isSameDay)

                  return (
                    <div key={field.key}>
                      <GeneralAvailabilityRow
                        isSameDay={isSameDay}
                        startTime={startTime}
                        endTime={endTime}
                        onRemove={() => onRemove(index)}
                        onAdd={shouldShowAdd ? () => onAdd(currentDate, true, index + 1) : null}
                        onChangeDate={date => onChangeDate(date, index)}
                        date={currentDate}
                        onChangeStartTime={time => onChangeStartTime(time, index)}
                        onChangeEndTime={time => onChangeEndTime(time, index)}
                        field={field}
                        minDate={dates[dateIndex - 1] || previousVisitFirstAvailabilityDate}
                        maxDate={dates[dateIndex + 1]}
                        minTime={minTime}
                        maxTime={maxTime}
                        missingPeriod={missingPeriod}
                      />
                    </div>
                  )
                })}
                <div
                  className="appointment-schedule-general-availability__new"
                  onClick={() => onAdd(null, false, fields.length)}
                >
                  {intlAvailability('new')}
                </div>
              </div>
            )
          }}
        </Form.List>
      </DatacFormItem>
    </div>
  )
}
