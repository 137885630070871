import './RecruitmentStudiesNumbers.less'

import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { DatacCard, DatacIcon, DatacTitle } from '../../../common'
import { StudiesCount } from '../RecruitmentStudiesContent'

export const RecruitmentStudiesNumbers: React.FC<StudiesCount> = ({
  recruitingCount,
  draftCount,
  endedCount,
  archivedCount,
  allCount
}) => {
  const intlNumbers = useScopedIntl('recruitment.studies.numbers')

  const recruitingPercent = `${Math.round((recruitingCount / allCount) * 100)}%`
  return (
    <div className="recruitment-studies-numbers">
      <div className="datac-card recruitment-studies-numbers__total">
        <div className="recruitment-studies-numbers__total__cell">
          <DatacIcon name="fileText" raw />
          <DatacTitle className="recruitment-studies-numbers__total__count">{allCount}</DatacTitle>
          <div className="recruitment-studies-numbers__total__label">{intlNumbers('total')}</div>
        </div>
        <div className="recruitment-studies-numbers__total__cell">
          <div className="recruitment-studies-numbers__total__recruiting">
            <div className="recruitment-studies-numbers__total__indicator" />
            <div>
              <div>
                <span className="recruitment-studies-numbers__total__recruiting__count">{recruitingCount}</span>
                <span className="recruitment-studies-numbers__total__recruiting__bullet">&bull;</span>
                <span className="recruitment-studies-numbers__total__recruiting__percent">{recruitingPercent}</span>
              </div>
              <div className="recruitment-studies-numbers__total__label">{intlNumbers('recruiting')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="recruitment-studies-numbers__cards">
        <DatacCard color="yellow" description={intlNumbers('recruiting')} value={recruitingCount} />
        <DatacCard color="grey" description={intlNumbers('draft')} value={draftCount} />
      </div>
      <div className="recruitment-studies-numbers__cards">
        <DatacCard color="green" description={intlNumbers('ended')} value={endedCount} />
        <DatacCard color="red" description={intlNumbers('archived')} value={archivedCount} />
      </div>
    </div>
  )
}
