import './ConfirmationPayments.less'

import { Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Participant, ParticipantStatus, PaymentStatus } from '../../../../../../requests'
import { maxCommentLength, validateRequired } from '../../../../../../validation'
import {
  DatacAvatar,
  DatacFormItem,
  DatacIcon,
  DatacInformationMessage,
  DatacRecordStatusTag,
  DatacTitle,
  DatacTooltip
} from '../../../../../common'

enum ConfirmPaymentType {
  cancelled,
  default
}

interface Props {
  theoreticalAmount: number
  applicationStatus: ParticipantStatus
  setPaymentAmount: (amount?: number) => void
  paymentAmount: number
  currency: string
  paymentComment: string
  setPaymentComment: (comment: string) => void
  isAddingComment: boolean
  setIsAddingComment: (isAddingComment: boolean) => void
  participants: Participant[]
}

export const ConfirmationPayments: React.FC<Props> = ({
  setPaymentAmount,
  paymentAmount,
  theoreticalAmount,
  applicationStatus,
  currency,
  paymentComment,
  setPaymentComment,
  isAddingComment,
  setIsAddingComment,
  participants
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const intl = useScopedIntl('')
  const [confirmationPaymentType, setConfirmationPaymentType] = useState(ConfirmPaymentType.default)
  const [editCancelledPayment, setEditCancelledPayment] = useState(!!paymentAmount)
  const [isCommentTriggered, setIsCommentTriggered] = useState(isAddingComment)
  const [isCommentRequired, setIsCommentRequired] = useState(false)
  const [participantsWithPayments, setParticipantsWithPayments] = useState<Participant[]>()

  useEffect(() => {
    setIsCommentRequired(
      confirmationPaymentType === ConfirmPaymentType.cancelled
        ? paymentAmount !== 0
        : theoreticalAmount !== paymentAmount
    )
  }, [confirmationPaymentType, theoreticalAmount, paymentAmount])

  useEffect(() => {
    setIsAddingComment(confirmationPaymentType != null && (isCommentRequired || isCommentTriggered))
  }, [confirmationPaymentType, applicationStatus, isCommentRequired, isCommentTriggered])

  useEffect(() => {
    const donePaymentStatuses = [
      PaymentStatus.Approved,
      PaymentStatus.Processing,
      PaymentStatus.Error,
      PaymentStatus.Paid
    ]

    const participantsWithPayments = participants.filter(p => donePaymentStatuses.includes(p.paymentStatus))
    setParticipantsWithPayments(participantsWithPayments)

    if (participants.length === participantsWithPayments.length) {
      setConfirmationPaymentType(null)
      return
    }

    if ([ParticipantStatus.Excluded, ParticipantStatus.Disqualified].includes(applicationStatus)) {
      setConfirmationPaymentType(ConfirmPaymentType.cancelled)
      setPaymentAmount(paymentAmount || 0)
      return
    }

    setConfirmationPaymentType(ConfirmPaymentType.default)
    setPaymentAmount(theoreticalAmount)
  }, [participants])

  return (
    <div className="candidates-table-confirmation-payments">
      <DatacTitle size="medium">{intlConfirmation('payments')}</DatacTitle>
      {confirmationPaymentType === ConfirmPaymentType.cancelled && (
        <div className="candidates-table-confirmation-payments__amount">
          {editCancelledPayment ? (
            <>
              <InputNumber
                min={0}
                step={0.01}
                defaultValue={paymentAmount}
                onChange={amount => setPaymentAmount(Number(amount))}
              />
              <Input disabled defaultValue={currency} />
              <span>
                {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground noDot />
            </>
          ) : (
            <div className="candidates-table-confirmation-payments__amount__cancelled">
              <span>
                {theoreticalAmount} {currency}
              </span>
              <DatacRecordStatusTag status={PaymentStatus.Cancelled} coloredBackground noDot />
              <DatacIcon name="edit" onClick={() => setEditCancelledPayment(true)} />
            </div>
          )}
        </div>
      )}
      {confirmationPaymentType === ConfirmPaymentType.default && (
        <div className="candidates-table-confirmation-payments__amount">
          <InputNumber
            min={0}
            step={0.01}
            defaultValue={paymentAmount != null ? paymentAmount : theoreticalAmount}
            onChange={amount => setPaymentAmount(Number(amount))}
          />
          <Input disabled defaultValue={currency} />
          <span>
            {intlConfirmation('planned_payments')}: {theoreticalAmount} {currency}
          </span>
          <DatacRecordStatusTag status={PaymentStatus.Approved} coloredBackground />
        </div>
      )}
      {isAddingComment ? (
        <div className="candidates-table-confirmation-payments__comment">
          <DatacFormItem
            name="comment"
            label={intlConfirmation('payment_comment')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              type="large"
              maxLength={maxCommentLength}
              value={paymentComment}
              onChange={e => setPaymentComment(e.currentTarget.value)}
            />
          </DatacFormItem>
          {!isCommentRequired && <DatacIcon name="x" type="red" onClick={() => setIsCommentTriggered(false)} />}
        </div>
      ) : (
        confirmationPaymentType != null && (
          <button
            type="button"
            className="raw visit-list-table-confirmation-payments__add-comment"
            onClick={() => setIsCommentTriggered(true)}
          >
            <DatacIcon name="plus" type="transparent" />
            {intlConfirmation('add_payment_comment')}
          </button>
        )
      )}

      {!!participantsWithPayments?.length && (
        <>
          <DatacInformationMessage type="info" message={intlConfirmation('payment_already_done')} />
          {participantsWithPayments.map(p => (
            <div key={p.id} className="candidates-table-confirmation-payments__payment">
              <DatacAvatar photoThumbnail={p.photoThumbnail} fullName={`${p.firstName} ${p.lastName}`} icon="dollar" />
              <div className="candidates-table-confirmation-payments__payment__info">
                <div>{`${p.firstName} ${p.lastName}`}</div>
                <div>
                  <span>
                    {p.paymentAmount} {p.paymentCurrency}
                  </span>
                  {p.paymentCreatedAt?.format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div>
                <DatacTooltip placement="top" title={p.paymentDate?.format('YYYY-MM-DD HH:mm')} trigger="hover">
                  <div>
                    <DatacRecordStatusTag status={p.paymentStatus} coloredBackground />
                  </div>
                </DatacTooltip>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}
