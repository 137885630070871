import { Tooltip } from 'antd'
import React from 'react'
import { Scrollbar } from 'react-scrollbars-custom'

import { useScopedIntl } from '../../../../../hooks'
import { ParticipantStatus } from '../../../../../requests'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

const triangle = (
  <svg width="10" height="100" viewBox="0 0 10 100">
    <polygon points="0,0 10,50 0,100" fill="currentColor" />
  </svg>
)

interface FunnelElement {
  status: ParticipantStatus
  value: number
}

export const ParticipantsDashboardFunnel: React.FC = () => {
  const intlStatus = useScopedIntl('status')
  const { study, setFilters, toggleShowFilters } = useRecruitmentStudyDetailsStore()

  const filterByStatus = (status: ParticipantStatus) => {
    setFilters({ status: [status] })
  }

  const elements: FunnelElement[] = [
    {
      status: ParticipantStatus.Prospect,
      value: study.countProspect
    },
    {
      status: ParticipantStatus.Contacted,
      value: study.countContacted
    },
    {
      status: ParticipantStatus.Interested,
      value: study.countInterested
    },
    {
      status: ParticipantStatus.Qualified,
      value: study.countQualified
    },
    {
      status: ParticipantStatus.Enrolled,
      value: study.countEnrolled
    },
    {
      status: ParticipantStatus.Completed,
      value: study.countCompleted
    },
    {
      status: ParticipantStatus.Disqualified,
      value: study.countDisqualified
    },
    {
      status: ParticipantStatus.NotInterested,
      value: study.countNotInterested
    },
    {
      status: ParticipantStatus.Excluded,
      value: study.countExcluded
    }
  ]

  const onElementHover = (event: React.MouseEvent<HTMLElement>) => {
    const label = event.currentTarget.children[0].children[0] as HTMLElement
    event.currentTarget.classList.toggle(
      'truncated',
      label.offsetWidth < label.scrollWidth || label.offsetHeight < label.scrollHeight
    )
  }

  return (
    <div className="participants-dashboard-funnel">
      <Scrollbar
        className="participants-dashboard-funnel__scroll"
        noScrollY
        disableTrackXWidthCompensation
        style={{ width: '100%', height: '17rem' }}
      >
        <div className="participants-dashboard-funnel__content">
          {elements.map(element => (
            <button
              type="button"
              className="raw participants-dashboard-funnel__element"
              key={element.status}
              data-name={element.status.toLowerCase()}
              onMouseEnter={onElementHover}
              onClick={() => {
                filterByStatus(element.status)
                toggleShowFilters('filter', true)
              }}
            >
              <span>
                <span>
                  <Tooltip
                    title={intlStatus(element.status.toLowerCase())}
                    placement="bottom"
                    open
                    getPopupContainer={el => el.closest('button')}
                  >
                    {intlStatus(element.status.toLowerCase())}
                  </Tooltip>
                </span>
              </span>
              <div className="badge">{element.value}</div>
              {triangle}
            </button>
          ))}
        </div>
      </Scrollbar>
    </div>
  )
}
