import './ScheduleListSlotsSettingsPopup.less'

import { Switch } from 'antd'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import { UserConfigKey } from '../../../../../../../../requests'
import { UserContext } from '../../../../../../../auth'

interface ScheduleListSlotsSettingsPopupProps {
  showWeekends: boolean
  setShowWeekends: (on: boolean) => void
  startsOnSunday: boolean
  setStartsOnSunday: (on: boolean) => void
}

export const ScheduleListSlotsSettingsPopup: React.VFC<ScheduleListSlotsSettingsPopupProps> = ({
  showWeekends,
  setShowWeekends,
  startsOnSunday,
  setStartsOnSunday
}) => {
  const intlSettings = useScopedIntl('calendar.settings')
  const { user } = useContext(UserContext)
  const onShowWeekendsChange = (checked: boolean) => {
    setShowWeekends(checked)
    user.setConfigValue(UserConfigKey.CalendarShowWeekends, checked)

    if (!checked) {
      setStartsOnSunday(false)
      user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, false)
    }
  }

  const onStartsOnSundayChange = (checked: boolean) => {
    setStartsOnSunday(checked)
    user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, checked)
  }

  return (
    <div className="schedule-list-slots-settings-popup">
      <div className="schedule-list-slots-settings-popup__header">{intlSettings('title')}</div>
      <div className="schedule-list-slots-settings-popup__row">
        <Switch checked={showWeekends} onChange={onShowWeekendsChange} />
        <span>{intlSettings('show_weekends')}</span>
      </div>
      <div className="schedule-list-slots-settings-popup__row">
        <Switch disabled={!showWeekends} checked={startsOnSunday} onChange={onStartsOnSundayChange} />
        <span>{intlSettings('start_week_on_sunday')}</span>
      </div>
    </div>
  )
}
