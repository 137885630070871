import './VisitListTableConfirmation.less'

import { Button, Form } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import {
  BookedVisitStatus,
  DisqualifiedCause,
  ParticipantStatus,
  PaymentType,
  ScheduleBookedVisit,
  VisitConfirmationOptions,
  confirmVisits,
  fetchAllSelectedBookedVisits,
  fetchBookedVisitsCauses
} from '../../../../../../../requests'
import {
  DatacAvatar,
  DatacBox,
  DatacIcon,
  DatacMessage,
  DatacModal,
  DatacPopconfirm,
  DatacRecordStatusTag,
  DatacTitle
} from '../../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../../RecruitmentStudyDetailsStore'
import { ApplicationStatus } from './ApplicationStatus'
import { ConfirmationPayments } from './ConfirmationPayments'
import { ConfirmationSuccess } from './ConfirmationSuccess'
import { VisitStatus } from './VisitStatus'

const getIsNextDisabled = (
  currentStep: number,
  confirmationOptions: VisitConfirmationOptions,
  paymentType: PaymentType,
  isAlreadyPaid = false
) => {
  if (currentStep === 1) return !confirmationOptions?.visitStatus
  if (currentStep === 2) return !confirmationOptions.applicationStatus
  if (paymentType === PaymentType.Visit) return confirmationOptions.paymentAmount == null
  return (
    confirmationOptions.applicationStatus === ParticipantStatus.Completed &&
    confirmationOptions.paymentAmount == null &&
    !isAlreadyPaid
  )
}

interface VisitListTableConfirmationProps {
  selectedVisits: ScheduleBookedVisit[]
  isOpened: boolean
  onClose: () => void
  filters: Record<string, string[]>
  search: string
  isEverythingSelected: boolean
  targetStatus: BookedVisitStatus
  isUpdatingCompletedVisit: boolean
}

export const VisitListTableConfirmation: React.FC<VisitListTableConfirmationProps> = ({
  selectedVisits,
  isOpened,
  onClose,
  filters,
  search,
  isEverythingSelected,
  targetStatus,
  isUpdatingCompletedVisit
}) => {
  const intl = useScopedIntl('')
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')
  const [currentVisits, setCurrentVisits] = useState<ScheduleBookedVisit[]>()
  const [paidVisits, setPaidVisits] = useState<ScheduleBookedVisit[]>()
  const [cancelledVisits, setCancelledVisits] = useState<ScheduleBookedVisit[]>()
  const [currentStep, setCurrentStep] = useState(1)
  const { study, triggerReloadParticipantsTable } = useRecruitmentStudyDetailsStore()
  const [confirmationOptions, setConfirmationOptions] = useState<VisitConfirmationOptions>()
  const [notDoneCauses, setNotDoneCauses] = useState<string[]>([])
  const [isAddingComment, setIsAddingComment] = useState(false)
  const [isCloseConfirmOpen, setCloseConfirmOpen] = useState(false)
  const [formInstance] = Form.useForm()

  useEffect(() => {
    fetchBookedVisitsCauses({
      onSuccess: setNotDoneCauses,
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }, [])

  const updateVisitList = (bookedVisits: ScheduleBookedVisit[]) => {
    if (!bookedVisits.length) {
      onClose()
      return
    }

    const isOneVisit = bookedVisits.every(v => v.visitId === bookedVisits[0].visitId)
    if (!isOneVisit) {
      DatacMessage.error(
        intlConfirmation('wrong_selection.title'),
        intlConfirmation('wrong_selection.visits.description')
      )
      onClose()
      return
    }

    const notScheduledVisit = bookedVisits.find(v => v.status !== BookedVisitStatus.Scheduled)
    if (notScheduledVisit && !isUpdatingCompletedVisit) {
      DatacMessage.error(
        intlConfirmation('wrong_selection.title'),
        intlConfirmation('wrong_selection.status.description')
      )
      onClose()
      return
    }

    const paidVisits = study.paymentType === PaymentType.Study ? bookedVisits.filter(v => v.paymentStatus) : []
    const onlySomePaymentsInPaymentPerStudy = paidVisits.length && bookedVisits.find(v => !v.paymentStatus)
    if (onlySomePaymentsInPaymentPerStudy) {
      DatacMessage.error(
        intlConfirmation('wrong_selection.title'),
        intlConfirmation('wrong_selection.payments.description')
      )
      onClose()
      return
    }

    setCurrentVisits(bookedVisits)
    setPaidVisits(paidVisits)
  }

  useEffect(() => {
    if (!isOpened) return
    setCurrentStep(1)
    setConfirmationOptions({
      visitStatus: null,
      visitStatusCause: null,
      applicationStatus: null,
      paymentAmount: null,
      paymentComment: null,
      disqualifiedCause: DisqualifiedCause.NotEligible
    })
    setCancelledVisits([])
    setCurrentVisits([])

    if (selectedVisits && !isEverythingSelected) {
      updateVisitList(selectedVisits)
      return
    }

    fetchAllSelectedBookedVisits(
      {
        studyId: study.id,
        options: {
          search,
          filters
        }
      },
      {
        onSuccess: updateVisitList,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [isOpened])

  const getCauses = () => {
    switch (confirmationOptions?.visitStatus) {
      case BookedVisitStatus.NotDone:
        return notDoneCauses
      case BookedVisitStatus.Cancelled:
        return [intlConfirmation('cause.cancelled_by_participant'), intlConfirmation('cause.cancelled_by_center')]
      default:
        return []
    }
  }

  const goToNextStep = () => {
    if (currentStep === 4) {
      onClose()
      return
    }

    if (currentStep === 3) {
      formInstance.validateFields()
      if (isAddingComment && !confirmationOptions.paymentComment) return

      confirmVisits(
        {
          studyId: study.id,
          slotSubjectIds: currentVisits.map(v => Number(v.id)),
          confirmationOptions: isAddingComment
            ? confirmationOptions
            : { ...confirmationOptions, paymentComment: undefined }
        },
        {
          onSuccess: cancelledVisits => {
            setCancelledVisits(cancelledVisits)
            triggerReloadParticipantsTable()
            setCurrentStep(4)
          },
          onNoCurrency: () =>
            DatacMessage.error(intlConfirmation('no_currency.title'), intlConfirmation('no_currency.description')),
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
      return
    }

    setCurrentStep(currentStep + 1)
  }

  const theoreticalAmount =
    study.paymentType === PaymentType.Study ? study.payment : currentVisits?.[0]?.theoreticalAmount

  const modalTitle =
    currentVisits?.length === 1
      ? intlConfirmation('title.single')
      : intlConfirmation('title', { count: currentVisits?.length })

  const handleCloseConfirmOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setCloseConfirmOpen(newOpen)
      return
    }
    if (currentStep === 4) {
      onClose()
    } else {
      setCloseConfirmOpen(newOpen)
    }
  }

  useEffect(() => {
    if (isAddingComment) return

    setConfirmationOptions(options => ({ ...options, paymentComment: null }))
    formInstance.setFieldsValue({ comment: null })
  }, [isAddingComment])

  const getNextButtonLabel = () => {
    if (currentStep === 4) return intl('common.close')
    if (currentStep === 3) return intl('common.finish')
    return intl('common.next')
  }

  return (
    <Form form={formInstance}>
      <DatacModal
        className="visit-list-table-confirmation"
        isOpened={isOpened && !!currentVisits?.length}
        onClose={onClose}
        noFooter
        noHeader
        confirmCancel
        confirmCancelLabel={intlConfirmation('confirm_cancel')}
      >
        <div className="visit-list-table-confirmation__title">
          <DatacTitle size="medium">{modalTitle}</DatacTitle>
          <DatacPopconfirm
            title={intlConfirmation('confirm_cancel')}
            onConfirm={onClose}
            open={isOpened && isCloseConfirmOpen}
            onOpenChange={handleCloseConfirmOpenChange}
            getPopupContainer={el => el.closest('.visit-list-table-confirmation__title')}
          >
            <DatacIcon name="x" type="grey" />
          </DatacPopconfirm>
        </div>
        <div className="visit-list-table-confirmation__body">
          <DatacBox className="visit-list-table-confirmation__left">
            <div className="visit-list-table-confirmation__left__participants">
              {currentVisits?.map(visit => (
                <div key={visit.id} className="visit-list-table-confirmation__left__participants__participant">
                  <DatacAvatar photoThumbnail={visit.participantPhotoThumbnail} fullName={visit.participantName} />
                  <div className="visit-list-table-confirmation__left__participants__participant__info">
                    <div className="visit-list-table-confirmation__left__participants__participant__info__visit">
                      {visit.visitName}
                    </div>
                    <div className="visit-list-table-confirmation__left__participants__participant__info__name">
                      {visit.participantName}
                    </div>
                    <DatacRecordStatusTag status={visit.applicationStatus} coloredBackground />
                  </div>
                </div>
              ))}
            </div>
            <div className="visit-list-table-confirmation__left__study">
              {intlConfirmation('study', { name: study.name })}{' '}
            </div>
          </DatacBox>
          <div className="visit-list-table-confirmation__right">
            <div className="visit-list-table-confirmation__right__steps">
              <div className="visit-list-table-confirmation__right__steps__lines">
                <div className="visit-list-table-confirmation__right__steps__lines__line active" />
                <div
                  className={classNames('visit-list-table-confirmation__right__steps__lines__line', {
                    active: currentStep > 1
                  })}
                />
                <div
                  className={classNames('visit-list-table-confirmation__right__steps__lines__line', {
                    active: currentStep > 2
                  })}
                />
              </div>
              {currentStep === 4
                ? intlConfirmation('success')
                : intlConfirmation('step', { current: currentStep, total: 3 })}
            </div>
            <div className="visit-list-table-confirmation__right__content">
              {currentStep === 1 && (
                <VisitStatus
                  selectedStatus={confirmationOptions?.visitStatus}
                  onStatusChange={visitStatus => setConfirmationOptions(options => ({ ...options, visitStatus }))}
                  causes={getCauses()}
                  selectedCause={confirmationOptions?.visitStatusCause}
                  onCauseChange={visitStatusCause =>
                    setConfirmationOptions(options => ({ ...options, visitStatusCause }))
                  }
                  targetStatus={targetStatus}
                  isUpdatingCompletedVisit={isUpdatingCompletedVisit}
                />
              )}
              {currentStep === 2 && (
                <ApplicationStatus
                  selectedStatus={confirmationOptions?.applicationStatus}
                  onStatusChange={applicationStatus =>
                    setConfirmationOptions(options => ({ ...options, applicationStatus }))
                  }
                  initialParticipantStatus={currentVisits[0]?.applicationStatus}
                  initialVisitStatus={currentVisits[0]?.status}
                  selectedCause={confirmationOptions?.disqualifiedCause}
                  onCauseChange={disqualifiedCause =>
                    setConfirmationOptions(options => ({ ...options, disqualifiedCause }))
                  }
                />
              )}
              {currentStep === 3 && (
                <ConfirmationPayments
                  applicationStatus={confirmationOptions?.applicationStatus}
                  visitStatus={confirmationOptions?.visitStatus}
                  theoreticalAmount={theoreticalAmount}
                  currency={study.currency}
                  paymentType={study.paymentType}
                  setPaymentAmount={paymentAmount => setConfirmationOptions(options => ({ ...options, paymentAmount }))}
                  paymentAmount={confirmationOptions.paymentAmount}
                  paymentComment={confirmationOptions.paymentComment}
                  setPaymentComment={paymentComment =>
                    setConfirmationOptions(options => ({ ...options, paymentComment }))
                  }
                  isAddingComment={isAddingComment}
                  setIsAddingComment={setIsAddingComment}
                  paidVisits={paidVisits}
                />
              )}
              {currentStep === 4 && <ConfirmationSuccess cancelledVisits={cancelledVisits} />}
            </div>
            <div className="visit-list-table-confirmation__right__footer">
              {currentStep > 1 && currentStep < 4 ? (
                <Button type="default" size="large" onClick={() => setCurrentStep(currentStep - 1)}>
                  {intl('common.back')}
                </Button>
              ) : (
                <div />
              )}
              <Button
                type="primary"
                size="large"
                disabled={getIsNextDisabled(currentStep, confirmationOptions, study.paymentType, !!paidVisits?.length)}
                onClick={goToNextStep}
              >
                {getNextButtonLabel()}
              </Button>
            </div>
          </div>
        </div>
      </DatacModal>
    </Form>
  )
}
