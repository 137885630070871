import '../../../studies/StudyDetailsContent/InviteSubjectsModal/InviteSubjectsModal.less'

import { Form, Input, Radio } from 'antd'
import { EditorState, convertToRaw } from 'draft-js'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  MessageType,
  Participant,
  ParticipantStatus,
  countResendParticipantInvites,
  resendParticipantInvites
} from '../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { DatacFormItem, DatacInformationMessage, DatacMessage, DatacModal } from '../../../common'
import { EditorInput } from '../../../shared/Editor'
import { useRecruitmentStudyDetailsStore } from '../RecruitmentStudyDetailsStore'

const allowedStatuses = [ParticipantStatus.Prospect, ParticipantStatus.Contacted, ParticipantStatus.Interested]

export interface InvitationFormData {
  subjectIds: string[] | string
  messageType: MessageType.Email | MessageType.Sms
  emailSubject: string
  emailBody: EditorState
  smsBody: string
}

interface ResendInvitationModalProps {
  isOpened: boolean
  participants: Participant[]
  filters: Record<string, string[]>
  search: string
  isEverythingSelected: boolean
  onClose: () => void
  onInvitationsSent: (count: number) => void
}

export const ResendInvitationModal: React.FC<ResendInvitationModalProps> = ({
  isOpened,
  participants,
  filters,
  search,
  isEverythingSelected,
  onClose,
  onInvitationsSent
}) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('recruitment.study.participants.invite_subjects_modal')
  const { study } = useRecruitmentStudyDetailsStore()
  const [formInstance] = Form.useForm()
  const [isInvitingSubjects, setIsInvitingSubjects] = useState(false)
  const [toSend, setToSend] = useState<{ [MessageType.Email]: number; [MessageType.Sms]: number }>(null)
  const [messageType, setMessageType] = useState<MessageType.Email | MessageType.Sms>(MessageType.Email)

  useEffect(() => {
    if (!isOpened) return
    if (participants?.length && !isEverythingSelected) {
      setToSend({
        [MessageType.Email]: participants.filter(
          participant => allowedStatuses.includes(participant.status) && participant.email
        ).length,
        [MessageType.Sms]: participants.filter(
          participant => allowedStatuses.includes(participant.status) && participant.internationalPhoneNumber
        ).length
      })
      return
    }
    countResendParticipantInvites(
      {
        studyId: study.id,
        search,
        status: filters?.status as ParticipantStatus[]
      },
      {
        onSuccess: count => {
          setToSend(count)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [isOpened])

  const onSubmit = (data: InvitationFormData) => {
    setIsInvitingSubjects(true)

    const messageSubject = messageType === MessageType.Email ? data.emailSubject : null
    const getMessageBody = () => {
      if (messageType === MessageType.Email) return JSON.stringify(convertToRaw(data.emailBody.getCurrentContent()))
      if (messageType === MessageType.Sms) return data.smsBody
      return null
    }

    resendParticipantInvites(
      {
        participantIds: participants?.length && !isEverythingSelected ? participants.map(p => p.id) : undefined,
        studyId: study.id,
        messageType: data.messageType,
        messageSubject,
        messageBody: getMessageBody(),
        search,
        status: filters?.status as ParticipantStatus[]
      },
      {
        onSuccess: count => {
          setIsInvitingSubjects(false)
          onClose()
          onInvitationsSent(count)
        },
        onRequestError: code => {
          setIsInvitingSubjects(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const messageTypeOptions = [
    { label: intl('common.message_type.email'), value: MessageType.Email },
    { label: intl('common.message_type.sms'), value: MessageType.Sms }
  ]

  return (
    <DatacModal
      className="invite-subjects-modal invite-subjects-modal--with-editor"
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={() => formInstance.submit()}
      destroyOnClose
      loading={isInvitingSubjects}
      afterClose={() => formInstance.resetFields()}
    >
      <div className="invite-subjects-modal__body">
        {toSend && (
          <DatacInformationMessage
            message={
              toSend[messageType] === 1
                ? intlModal('participant_count_info_one')
                : intlModal('participant_count_info', { count: toSend[messageType] })
            }
          />
        )}
        <Form name="invite-subjects-form" onFinish={onSubmit} form={formInstance}>
          <DatacFormItem
            name="messageType"
            className="invite-subjects-modal__type"
            label={intlModal('message_type.label')}
            initialValue={messageType}
          >
            <Radio.Group
              options={messageTypeOptions}
              value={messageType}
              onChange={e => setMessageType(e.target.value)}
            />
          </DatacFormItem>
          {messageType === MessageType.Email && (
            <>
              <DatacFormItem
                name="emailSubject"
                label={intlModal('email_subject.label')}
                validate={validateRequired(intlModal('email_subject.validation'))}
              >
                <Input
                  size="large"
                  maxLength={maxTitleLengthLong}
                  placeholder={intlModal('email_subject.placeholder')}
                />
              </DatacFormItem>
              <EditorInput fieldName="emailBody" label={intlModal('email_body.label')} initialValue={null} required />
            </>
          )}
          {messageType === MessageType.Sms && (
            <DatacFormItem
              name="smsBody"
              label={intlModal('sms_body.label')}
              validate={validateRequired(intlModal('sms_body.validation'))}
            >
              <Input.TextArea
                className="datac-message-subject-message__body-input"
                maxLength={130}
                rows={2}
                placeholder={intlModal('sms_body.placeholder')}
              />
            </DatacFormItem>
          )}
        </Form>
      </div>
    </DatacModal>
  )
}
