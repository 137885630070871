import './StudyUsersEditModal.less'

import { Form } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { UserData, editRecruitmentStudyUser } from '../../../../../../requests'
import { validateMinItems } from '../../../../../../validation'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../RecruitmentStudyDetailsStore'

interface EditFormData {
  centerIds: string[]
}

interface Props {
  isOpened: boolean
  onClose: () => void
  userToEdit: UserData
  onUserEdited: () => void
  centersOptions: DatacOption[]
}

export const StudyUsersEditModal: React.FC<Props> = ({
  isOpened,
  onClose,
  userToEdit,
  onUserEdited,
  centersOptions
}) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('studies.users.edit_modal')
  const [formInstance] = Form.useForm()
  const [isSaving, setIsSaving] = useState(false)
  const { study } = useRecruitmentStudyDetailsStore()

  useEffect(() => {
    if (userToEdit) {
      formInstance.setFieldsValue({ centerIds: userToEdit.centers?.map(center => center.id) })
    }
  }, [userToEdit])

  const onSubmit = ({ centerIds }: EditFormData) => {
    setIsSaving(true)
    editRecruitmentStudyUser(
      { studyId: study.id, userId: userToEdit.id, centerIds },
      {
        onSuccess: () => {
          setIsSaving(false)
          onClose()
          onUserEdited()
        },
        onError: () => setIsSaving(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      title={intlModal('title')}
      onSubmit={() => formInstance.submit()}
      loading={isSaving}
      afterClose={() => formInstance.resetFields()}
      destroyOnClose
    >
      <div className="study-users-edit-modal__body">
        <div className="study-users-edit-modal__user-info">
          <DatacIcon name="user" size="xbig" type="blue" className="study-users-edit-modal__user-avatar" />
          <span className="study-users-edit-modal__user-name">{userToEdit?.name}</span>
        </div>
        <Form form={formInstance} name="study-users-edit-form" onFinish={onSubmit}>
          <DatacFormItem
            label={intlModal('centers.label')}
            name="centerIds"
            validate={validateMinItems(intlModal('centers.validation'), 1)}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              placeholder={intlModal('centers.placeholder')}
              options={centersOptions}
            />
          </DatacFormItem>
        </Form>
      </div>
    </DatacModal>
  )
}
