import './AppointmentScheduleFirst.less'

import { Button } from 'antd'
import React from 'react'

import NoSchedules from '../../../../../../assets/images/no-schedules.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { DatacTitle } from '../../../../../common'

export const AppointmentScheduleFirst: React.FC<{ onCreateClick: () => void }> = ({ onCreateClick }) => {
  const intlSchedules = useScopedIntl('recruitment.study.schedules')
  return (
    <div className="appointment-schedule-first">
      <NoSchedules />
      <DatacTitle size="medium">{intlSchedules('no_schedules.title')}</DatacTitle>
      <div className="appointment-schedule-first__description">{intlSchedules('no_schedules.description')}</div>
      <Button size="large" type="primary" onClick={onCreateClick}>
        {intlSchedules('create')}
      </Button>
    </div>
  )
}
