import { DatePicker, RefSelectProps } from 'antd'
import { Dayjs } from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'

import { getTimeAsNumber } from '../..'
import { useScopedIntl } from '../../../../../../../../hooks'
import { validateRequired } from '../../../../../../../../validation'
import { DatacFormItem, DatacIcon, DatacOption, DatacSelect } from '../../../../../../../common'

const defaultMinTime = '08:00'
const defaultMaxTime = '19:00'

interface GeneralAvailabilityRowProps {
  isSameDay: boolean
  startTime: string
  endTime: string
  onRemove: () => void
  onAdd: () => void
  onChangeDate: (date: Dayjs) => void
  date: Dayjs
  onChangeEndTime: (time: string) => void
  onChangeStartTime: (time: string) => void
  field: {
    fieldKey?: string | number
    name: string | number
    key: string | number
  }
  minDate?: Dayjs
  maxDate?: Dayjs
  minTime?: number
  maxTime?: number
  missingPeriod: number
}

export const GeneralAvailabilityRow: React.VFC<GeneralAvailabilityRowProps> = ({
  isSameDay,
  startTime,
  endTime,
  onAdd,
  onRemove,
  onChangeDate,
  date,
  onChangeStartTime,
  onChangeEndTime,
  field,
  minDate,
  maxDate,
  minTime,
  maxTime,
  missingPeriod
}) => {
  const intl = useScopedIntl('')
  const [currentStartTime, setCurrentStartTime] = useState(startTime)
  const [currentSearchStartTime, setCurrentSearchStartTime] = useState(startTime)
  const [currentEndTime, setCurrentEndTime] = useState(endTime)
  const [currentSearchEndTime, setCurrentSearchEndTime] = useState(endTime)
  const [currentStartTimeOptions, setCurrentStartTimeOptions] = useState<DatacOption[]>([])
  const [currentEndTimeOptions, setCurrentEndTimeOptions] = useState<DatacOption[]>([])
  const startTimeRef = useRef<RefSelectProps>(null)
  const endTimeRef = useRef<RefSelectProps>(null)

  const numberToTime = (number: number) => ({
    hour: Math.floor(number / 60),
    minute: number % 60
  })

  const timeToString = (time: { hour: number; minute: number }) =>
    `${time.hour.toString().padStart(2, '0')}:${time.minute.toString().padStart(2, '0')}`

  useEffect(() => {
    if (!currentStartTimeOptions?.length || !date || currentStartTime) return

    const minTime = currentStartTimeOptions.find(t => t.value === defaultMinTime)
      ? defaultMinTime
      : currentStartTimeOptions?.[0].value

    setCurrentStartTime(minTime)
    onChangeStartTime(minTime)
  }, [date, currentStartTimeOptions])

  useEffect(() => {
    setCurrentStartTimeOptions(getStartTimeOptions())
    setCurrentEndTimeOptions(getEndTimeOptions())

    if (currentStartTime && !currentEndTime && missingPeriod && currentStartTime < defaultMaxTime) {
      const neededEndTime = getTimeAsNumber(currentStartTime) + missingPeriod
      const newEndTime = timeToString(numberToTime(Math.min(maxTime, neededEndTime, getTimeAsNumber(defaultMaxTime))))

      onChangeEndTime(newEndTime)
      setCurrentEndTime(newEndTime)
    }
  }, [currentStartTime, currentEndTime, minTime, maxTime])

  const getAllTimeOptions = () => {
    const times = Array.from({ length: 24 }, (_, i) => i).reduce((acc, hour) => {
      ;[0, 15, 30, 45].forEach(minute => {
        acc.push(timeToString({ hour, minute }))
      })
      return acc
    }, [])
    return times.map(time => ({
      value: time,
      label: time
    }))
  }

  const getStartTimeOptions = () => {
    const times = getAllTimeOptions()
      .filter(time => !currentEndTime || getTimeAsNumber(time.value) < getTimeAsNumber(currentEndTime))
      .filter(time => getTimeAsNumber(time.value) >= minTime)
    return times
  }

  const getEndTimeOptions = () => {
    const times = getAllTimeOptions()
    times.push({ value: '23:59', label: '23:59' })
    const newTimes = times
      .filter(time => !currentStartTime || getTimeAsNumber(time.value) > getTimeAsNumber(currentStartTime))
      .filter(time => getTimeAsNumber(time.value) <= maxTime)
    return newTimes
  }

  const disableDate = (date: Dayjs) => {
    if (minDate && !date.isAfter(minDate, 'day')) return true
    if (maxDate && !date.isBefore(maxDate, 'day')) return true
    return false
  }

  const shouldChangeTime = (key: string, time: string) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
    return key === 'Enter' && timeRegex.test(time)
  }

  const onStartTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentSearchStartTime) || getTimeAsNumber(currentSearchStartTime) < minTime) return
    onChangeStartTime(currentSearchStartTime)
    setCurrentStartTime(currentSearchStartTime)
    startTimeRef.current.blur()
  }

  const onEndTimeKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!shouldChangeTime(e.key, currentSearchEndTime) || getTimeAsNumber(currentSearchEndTime) > maxTime) return
    onChangeEndTime(currentSearchEndTime)
    setCurrentEndTime(currentSearchEndTime)
    endTimeRef.current.blur()
  }

  return (
    <div className="appointment-schedule-general-availability__row">
      {!isSameDay ? (
        <DatacFormItem
          validate={validateRequired(intl('common.required'))}
          className="appointment-schedule-general-availability__row__date"
          name={[field.name, 'date']}
          fieldKey={[field.fieldKey, 'date']}
        >
          <DatePicker size="large" format="dddd, D MMM YYYY" disabledDate={disableDate} onChange={onChangeDate} />
        </DatacFormItem>
      ) : (
        <div className="appointment-schedule-general-availability__row__date" />
      )}
      <DatacFormItem
        validate={validateRequired(intl('common.required'))}
        name={[field.name, 'startTime']}
        fieldKey={[field.fieldKey, 'startTime']}
      >
        <DatacSelect
          showSearch
          selectRef={startTimeRef}
          onInputKeyDown={onStartTimeKeyPressed}
          options={currentStartTimeOptions}
          onChange={setCurrentStartTime}
          onSearch={setCurrentSearchStartTime}
          value={currentStartTime}
        />
      </DatacFormItem>
      <div className="appointment-schedule-general-availability__row__separator">-</div>
      <DatacFormItem
        validate={validateRequired(intl('common.required'))}
        name={[field.name, 'endTime']}
        fieldKey={[field.fieldKey, 'endTime']}
      >
        <DatacSelect
          showSearch
          selectRef={endTimeRef}
          onInputKeyDown={onEndTimeKeyPressed}
          options={currentEndTimeOptions}
          onChange={setCurrentEndTime}
          onSearch={setCurrentSearchEndTime}
        />
      </DatacFormItem>
      {onAdd ? (
        <DatacIcon type="blue" name="plus" onClick={onAdd} />
      ) : (
        <div className="appointment-schedule-general-availability__row__add" />
      )}
      <DatacIcon name="trash" type="red" onClick={onRemove} />
    </div>
  )
}
