import './AppointmentScheduleVisit.less'

import { format } from 'date-fns'
import dayjs from 'dayjs'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Schedule, ScheduleType, ScheduleVisit, ScheduleVisitAvailability } from '../../../../../../requests'
import { DatacIcon, DatacModal, DatacPopconfirm, DatacTitle } from '../../../../../common'
import { VisitSlotsCounts } from '../AppointmentSchedule'
import { AppointmentScheduleEdit } from './VisitEdit'
import { VisitHeader } from './VisitHeader'
import { AppointmentScheduleWeek } from './VisitWeek'

export const getTimeAsNumber = (time: string) => {
  if (!time) return null

  const [hour, minute] = time.split(':')
  return parseInt(hour, 10) * 60 + parseInt(minute, 10)
}

interface ChangeVisitWrapperProps {
  showConfirm: boolean
  onClick: () => void
}

export const ChangeVisitWrapper: React.FC<ChangeVisitWrapperProps> = ({ children, showConfirm, onClick }) => {
  const intlVisit = useScopedIntl('recruitment.study.schedules.visit')
  return showConfirm ? (
    <DatacPopconfirm title={intlVisit('confirm')} onConfirm={onClick}>
      {children}
    </DatacPopconfirm>
  ) : (
    <div onClick={onClick}>{children}</div>
  )
}

interface Props {
  onClose: () => void
  schedule: Schedule
  visitNumber: number
  onGoBack: () => void
  onUpdateScheduleVisit: (visit: ScheduleVisit) => void
  setNrOfVisitToEdit: (number: number) => void
  onUpdateSlotCounts: (created: number, needed: number) => void
  slotsCounts: VisitSlotsCounts[]
}

export const AppointmentScheduleVisit: React.FC<Props> = ({
  onClose,
  schedule,
  visitNumber,
  onGoBack,
  onUpdateScheduleVisit,
  setNrOfVisitToEdit,
  onUpdateSlotCounts,
  slotsCounts
}) => {
  const intlCalendar = useScopedIntl('calendar')
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  const [interval, setInterval] = useState(0)
  const [duration, setDuration] = useState(0)
  const [availability, setAvailability] = useState<ScheduleVisitAvailability[]>([])
  const [capacity, setCapacity] = useState(1)

  const goToPrevious = () => {
    setCurrentDate(dayjs(currentDate).subtract(1, 'week').toDate())
  }

  const goToNext = () => {
    setCurrentDate(dayjs(currentDate).add(1, 'week').toDate())
  }

  const missingSlotsCount = slotsCounts[visitNumber - 1]?.needed - slotsCounts[visitNumber - 1]?.created
  const additionalTimeForLastSlot = interval && duration > interval ? duration - interval : 0
  const missingPeriod = missingSlotsCount * (interval || duration) + additionalTimeForLastSlot

  const previousVisitFirstAvailabilityDate = schedule?.visits?.[visitNumber - 2]?.availability?.[0]?.date?.subtract(
    1,
    'day'
  )

  return (
    <DatacModal isOpened noFooter noHeader fullScreen onClose={onClose} className="appointment-schedule-visit__modal">
      <div className="appointment-schedule-visit">
        <VisitHeader
          visitNumber={visitNumber}
          onClose={onClose}
          schedule={schedule}
          setNumberOfVisitToEdit={setNrOfVisitToEdit}
          slotsCounts={slotsCounts}
        />
        <div className="appointment-schedule-visit__body">
          <AppointmentScheduleEdit
            visitNumber={visitNumber}
            visit={schedule?.visits?.find(v => v.number === visitNumber)}
            setVisit={visit => onUpdateScheduleVisit({ ...visit, number: visitNumber })}
            setInterval={setInterval}
            setDuration={setDuration}
            setAvailability={setAvailability}
            setCapacity={setCapacity}
            onGoBack={onGoBack}
            missingPeriod={missingPeriod}
            readOnlyCapacity={
              visitNumber > 1 && schedule.type === ScheduleType.Sequential && schedule?.visits?.[0]?.capacity
            }
            isLastVisit={schedule.visitsCount === visitNumber}
            previousVisitFirstAvailabilityDate={previousVisitFirstAvailabilityDate}
          />
          <div className="appointment-schedule-visit__body__right">
            <div className="appointment-schedule-visit__body__right__header">
              <div className="appointment-schedule-visit__body__right__header__spacer" />
              <button type="button" onClick={() => goToPrevious()}>
                <DatacIcon name="chevronLeft" />
              </button>
              <DatacTitle size="small">{format(currentDate, 'MMM yyyy')}</DatacTitle>
              <button type="button" onClick={() => goToNext()}>
                <DatacIcon name="chevronRight" />
              </button>
              <div className="appointment-schedule-visit__body__right__header__spacer" />
              <button
                type="button"
                className="appointment-schedule-visit__body__right__header__today"
                onClick={() => setCurrentDate(new Date())}
              >
                {intlCalendar('today')}
              </button>
            </div>
            <AppointmentScheduleWeek
              date={dayjs(currentDate)}
              setDate={setCurrentDate}
              interval={interval || duration}
              duration={duration}
              capacity={capacity}
              availability={availability}
              participantsCount={schedule.participantsCount}
              onUpdateSlotCounts={onUpdateSlotCounts}
            />
          </div>
        </div>
      </div>
    </DatacModal>
  )
}
