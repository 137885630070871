import './ApplicationStatus.less'

import React from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import { BookedVisitStatus, DisqualifiedCause, ParticipantStatus } from '../../../../../../../../requests'
import { DatacInformationMessage, DatacTitle } from '../../../../../../../common'
import { ConfirmationSelect } from '../../../../../ConfirmationSelect'

interface Props {
  selectedStatus: ParticipantStatus
  onStatusChange: (status: ParticipantStatus) => void
  initialParticipantStatus: ParticipantStatus
  initialVisitStatus: BookedVisitStatus
  selectedCause: DisqualifiedCause
  onCauseChange: (cause: DisqualifiedCause) => void
}

export const ApplicationStatus: React.FC<Props> = ({
  selectedStatus,
  onStatusChange,
  initialParticipantStatus,
  initialVisitStatus,
  selectedCause,
  onCauseChange
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  const getStatuses = () => {
    const statuses = initialVisitStatus === BookedVisitStatus.Completed ? [ParticipantStatus.Disqualified] : []

    if ([ParticipantStatus.Interested, ParticipantStatus.Qualified].includes(initialParticipantStatus)) {
      return [...statuses, ParticipantStatus.Enrolled, ParticipantStatus.Disqualified, ParticipantStatus.Completed]
    }

    return [...statuses, ParticipantStatus.Enrolled, ParticipantStatus.Excluded, ParticipantStatus.Completed]
  }

  const causes = [DisqualifiedCause.NotEligible, DisqualifiedCause.NotAvailable]

  return (
    <div className="visit-list-table-confirmation-application-status">
      <DatacTitle size="medium">{intlConfirmation('select_application_status')}</DatacTitle>
      <div className="visit-list-table-confirmation__select">
        {getStatuses().map(status => (
          <ConfirmationSelect
            status={status}
            onSelect={() => onStatusChange(status)}
            isSelected={selectedStatus === status}
            hasDescription
            key={status}
          />
        ))}
      </div>
      {selectedStatus === ParticipantStatus.Excluded && (
        <DatacInformationMessage
          type="error"
          title={intlConfirmation('rejected_warning.title')}
          message={intlConfirmation('rejected_warning.message')}
        />
      )}
      {selectedStatus === ParticipantStatus.Disqualified && (
        <>
          <DatacTitle size="medium">{intlConfirmation('cause')}</DatacTitle>
          <div className="visit-list-table-confirmation__cause">
            {causes?.map((cause, index) => (
              <div className={cause === selectedCause && 'active'} onClick={() => onCauseChange(cause)} key={index}>
                {intlConfirmation(cause.toLowerCase())}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
