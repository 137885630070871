import './RecruitmentStudySettingsLayout.less'

import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { AclAction, AclFeature } from '../../../../../requests'
import { routes } from '../../../../../routes'
import { UserContext } from '../../../../auth'
import { DatacMenuOption, DatacTitle } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsLayout: React.FC = ({ children }) => {
  const intlMenu = useScopedIntl('recruitment.study.settings.menu')
  const { user } = useContext(UserContext)
  const { study } = useRecruitmentStudyDetailsStore()

  return (
    <div className="recruitment-study-settings-layout">
      <div className="recruitment-study-settings-layout__menu">
        <div className="recruitment-study-settings-layout__menu__box">
          <DatacTitle size="small">{intlMenu('main')}</DatacTitle>
          <DatacMenuOption
            route={routes.recruitmentStudySettings(study.id)}
            label={intlMenu('general')}
            iconName="settings"
            exactMatch
          />

          <DatacMenuOption
            route={routes.recruitmentStudySettingsUsers(study.id)}
            label={intlMenu('users')}
            iconName="users"
          />

          {user.canDo(AclFeature.RecruitmentBuilder)(AclAction.Edit) && (
            <DatacMenuOption
              route={routes.recruitmentStudyBuilder(study.id)}
              label={intlMenu('builder')}
              iconName="builder"
            />
          )}

          <DatacMenuOption
            route={routes.recruitmentStudySettingsPayment(study.id)}
            label={intlMenu('payment')}
            iconName="dollar"
          />

          <DatacMenuOption
            route={routes.recruitmentStudySettingsQrCodes(study.id)}
            label={intlMenu('qr_codes')}
            iconName="grid"
          />
        </div>
        <div className="recruitment-study-settings-layout__menu__box">
          <DatacTitle size="small">{intlMenu('optional')}</DatacTitle>
          <DatacMenuOption
            route={routes.recruitmentStudySettingsDetails(study.id)}
            label={intlMenu('details')}
            iconName="clipboard"
          />

          <DatacMenuOption
            route={routes.recruitmentStudySettingsWashOut(study.id)}
            label={intlMenu('wash_out')}
            iconName="clock"
          />

          <DatacMenuOption
            route={routes.recruitmentStudySettingsLink(study.id)}
            label={intlMenu('edc_study')}
            iconName="link"
          />
        </div>
      </div>
      <main className="recruitment-study-settings-layout__content">{children}</main>
    </div>
  )
}
