import './AppointmentScheduleSetUp.less'

import { Button, Form, Input, InputNumber, Radio } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import { Schedule, ScheduleType } from '../../../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../../../validation'
import { DatacFormItem, DatacModal, DatacTitle } from '../../../../../common'

interface Props {
  onClose: () => void
  onSubmit: (data: Schedule) => void
  schedule: Schedule
}

export const AppointmentScheduleSetUp: React.FC<Props> = ({ onClose, onSubmit, schedule }) => {
  const intlSchedules = useScopedIntl('recruitment.study.schedules')
  const intl = useScopedIntl('')
  const [showType, setShowType] = useState(false)
  const [formInstance] = Form.useForm()

  useEffect(() => {
    formInstance.setFieldsValue({
      title: schedule?.title,
      visitsCount: schedule?.visitsCount,
      type: schedule?.type,
      participantsCount: schedule?.participantsCount
    })
    setShowType(schedule?.visitsCount > 1)
  }, [schedule])

  return (
    <Form onFinish={onSubmit} form={formInstance}>
      <DatacModal
        title={intlSchedules('visit_scheduler')}
        isOpened
        noFooter
        fullScreen
        onClose={onClose}
        className="appointment-schedule-set-up__modal"
      >
        <div className="appointment-schedule-set-up">
          <div className="appointment-schedule-set-up__top">{intlSchedules('set_up.top')}</div>
          <DatacTitle size="medium">{intlSchedules('set_up.title')}</DatacTitle>
          <DatacFormItem
            label={intlSchedules('set_up.title.label')}
            name="title"
            validate={validateRequired(intl('common.required'))}
            className="appointment-schedule-set-up__row"
          >
            <Input
              type="large"
              placeholder={intlSchedules('set_up.title.placeholder')}
              maxLength={maxTitleLengthLong}
            />
          </DatacFormItem>
          <DatacFormItem
            label={intlSchedules('set_up.visits_count.label')}
            name="visitsCount"
            validate={validateRequired(intl('common.required'))}
            className="appointment-schedule-set-up__row"
            initialValue={1}
          >
            <InputNumber
              placeholder={intlSchedules('set_up.visits_count.placeholder')}
              onChange={e => setShowType(e > 1)}
              max={300}
              min={1}
              precision={0}
            />
          </DatacFormItem>
          {showType && (
            <DatacFormItem
              label={intlSchedules('set_up.type.label')}
              name="type"
              validate={validateRequired(intl('common.required'))}
              className="appointment-schedule-set-up__row"
            >
              <Radio.Group>
                <Radio value={ScheduleType.Sequential}>
                  {intlSchedules('set_up.type.sequential')}
                  <div className="appointment-schedule-set-up__row__description">
                    {intlSchedules('set_up.type.sequential.description')}
                  </div>
                </Radio>
                <Radio value={ScheduleType.Open}>
                  {intlSchedules('set_up.type.open')}
                  <div className="appointment-schedule-set-up__row__description">
                    {intlSchedules('set_up.type.open.description')}
                  </div>
                </Radio>
              </Radio.Group>
            </DatacFormItem>
          )}
          <DatacFormItem
            label={intlSchedules('set_up.participants_count.label')}
            name="participantsCount"
            validate={validateRequired(intl('common.required'))}
            className="appointment-schedule-set-up__row"
          >
            <InputNumber
              placeholder={intlSchedules('set_up.participants_count.placeholder')}
              min={1}
              max={5000}
              precision={0}
            />
          </DatacFormItem>
          <div className="appointment-schedule-set-up__footer">
            <Button type="primary" size="large" onClick={() => formInstance.submit()}>
              {intlSchedules('start_creating')}
            </Button>
          </div>
        </div>
      </DatacModal>
    </Form>
  )
}
