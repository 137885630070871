import './AppointmentScheduleConfirmation.less'

import { Button } from 'antd'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import NewSchedule from '../../../../../../assets/images/new-schedule.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { RecordStatus, Schedule, updateSchedule } from '../../../../../../requests'
import { DatacMessage, DatacModal, DatacRecordStatusTag, DatacSwitch, DatacTitle } from '../../../../../common'

interface Props {
  onClose: () => void
  onCreateNew: () => void
  schedule: Schedule
  studyId: string
}

export const AppointmentScheduleConfirmation: React.FC<Props> = ({ onClose, onCreateNew, schedule, studyId }) => {
  const intlSchedules = useScopedIntl('recruitment.study.schedules')
  const intl = useScopedIntl('')
  const [firstDate, setFirstDate] = useState<Dayjs>()
  const [isPublished, setIsPublished] = useState(false)

  useEffect(() => {
    if (!schedule) return
    setFirstDate(schedule.visits?.[0]?.availability?.sort((a, b) => a.date.diff(b.date))[0]?.date)
  }, [schedule])

  const onChange = (isPublished: boolean) => {
    updateSchedule(
      { ...schedule, isPublished, studyId },
      {
        onSuccess: () => setIsPublished(isPublished),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }
  return (
    <DatacModal
      title={intlSchedules('visit_scheduler')}
      isOpened
      noFooter
      fullScreen
      onClose={onClose}
      className="appointment-schedule-confirmation__modal"
    >
      <div className="appointment-schedule-confirmation">
        <NewSchedule />
        <DatacTitle size="medium">{intlSchedules('confirmation.title')}</DatacTitle>
        <div className="appointment-schedule-confirmation__description">
          {intlSchedules('confirmation.description', { date: firstDate?.format('DD.MM.YYYY') })}
        </div>
        <div className="appointment-schedule-confirmation__status">
          <div className="appointment-schedule-confirmation__status__switch">
            <span>{intl('common.status')}</span>
            <DatacRecordStatusTag
              status={isPublished ? RecordStatus.Completed : RecordStatus.NotStarted}
              label={intlSchedules(isPublished ? 'published' : 'unpublished')}
            />
            <DatacSwitch checked={isPublished} onChange={onChange} showIcons />
          </div>
          <div className="appointment-schedule-confirmation__status__description">
            {intlSchedules(isPublished ? 'can_book_online' : 'cant_book_online')}
          </div>
        </div>
        <div className="appointment-schedule-confirmation__footer">
          <Button type="primary" onClick={onClose}>
            {intl('common.close')}
          </Button>
          <Button type="default" onClick={onCreateNew}>
            {intlSchedules('create')}
          </Button>
        </div>
      </div>
    </DatacModal>
  )
}
