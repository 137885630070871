import './VisitStatus.less'

import React, { useEffect } from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import { BookedVisitStatus } from '../../../../../../../../requests'
import { DatacTitle } from '../../../../../../../common'
import { ConfirmationSelect } from '../../../../../ConfirmationSelect'

interface Props {
  selectedStatus: BookedVisitStatus
  onStatusChange: (status: BookedVisitStatus) => void
  causes: string[]
  selectedCause: string
  onCauseChange: (cause: string) => void
  targetStatus: BookedVisitStatus
  isUpdatingCompletedVisit: boolean
}

export const VisitStatus: React.FC<Props> = ({
  selectedStatus,
  onStatusChange,
  causes,
  selectedCause,
  onCauseChange,
  targetStatus,
  isUpdatingCompletedVisit
}) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  useEffect(() => {
    onStatusChange(targetStatus)
  }, [targetStatus])

  const onStatusChangeHandle = (status: BookedVisitStatus) => {
    onStatusChange(status)
    onCauseChange('')
  }

  const availableStatuses = [
    isUpdatingCompletedVisit ? BookedVisitStatus.Scheduled : BookedVisitStatus.Completed,
    BookedVisitStatus.NotDone,
    BookedVisitStatus.Cancelled
  ]

  return (
    <div className="visit-list-table-confirmation-visit-status">
      <DatacTitle size="medium">{intlConfirmation('select_visit_status')}</DatacTitle>
      <div className="visit-list-table-confirmation__select">
        {availableStatuses.map(status => (
          <ConfirmationSelect
            key={status}
            status={status}
            onSelect={() => onStatusChangeHandle(status)}
            isSelected={selectedStatus === status}
            isVisitStatus
          />
        ))}
      </div>
      {!!causes?.length && <DatacTitle size="medium">{intlConfirmation('cause')}</DatacTitle>}
      <div className="visit-list-table-confirmation__cause">
        {causes?.map((cause, index) => (
          <div
            className={cause === selectedCause && 'active'}
            onClick={() => onCauseChange(cause === selectedCause ? '' : cause)}
            key={index}
          >
            {cause}
          </div>
        ))}
      </div>
    </div>
  )
}
