import './ConfirmationSuccess.less'

import React from 'react'

import CalendarCompleted from '../../../../../../assets/images/calendar-completed.svg'
import { useScopedIntl } from '../../../../../../hooks'
import { BookedVisitStatus, ScheduleBookedVisit } from '../../../../../../requests'
import { DatacAvatar, DatacBox, DatacRecordStatusTag, DatacTitle } from '../../../../../common'

interface Props {
  cancelledVisits: ScheduleBookedVisit[]
  multiple: boolean
}

export const ConfirmationSuccess: React.FC<Props> = ({ cancelledVisits, multiple }) => {
  const intlConfirmation = useScopedIntl('recruitment.study.schedules.table.confirmation')

  return (
    <div className="candidates-table-confirmation-success">
      <DatacTitle size="medium">{intlConfirmation(multiple ? 'changes_saved' : 'change_saved')}</DatacTitle>
      {cancelledVisits?.length ? (
        <>
          {intlConfirmation('cancelled_visits', { count: cancelledVisits.length })}
          {cancelledVisits.map(visit => (
            <DatacBox key={visit.id}>
              <DatacAvatar photoThumbnail={visit.participantPhotoThumbnail} fullName={visit.participantName} />
              <div className="candidates-table-confirmation-success__date">
                <div className="candidates-table-confirmation-success__date__day">{visit.date.format('DD')}</div>
                <div className="candidates-table-confirmation-success__date__month">
                  {visit.date.format('MMM').toLocaleUpperCase()}
                </div>
              </div>
              <div className="candidates-table-confirmation-success__visit">
                <div className="candidates-table-confirmation-success__visit__name">{visit.visitName}</div>
                <div className="candidates-table-confirmation-success__visit__hours">
                  {visit.date.format('dddd')}, {visit.startTime} - {visit.endTime}
                </div>
              </div>
              <DatacRecordStatusTag status={BookedVisitStatus.Cancelled} coloredBackground />
            </DatacBox>
          ))}
        </>
      ) : (
        <CalendarCompleted />
      )}
    </div>
  )
}
